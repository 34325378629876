<template>
  <span
    v-if="
      profile.role != 'User' ||
      (userSession &&
        userSession.displayParameters &&
        userSession.displayParameters.studies)
    "
  >
    <v-card>
      <v-card-title primary-title class="justify-center">
        <h1 class="text-center mb-3">Etudes</h1>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-row align-center justify-center">
          <v-col cols="12" md="4" v-show="profile.role != 'User'">
            <v-select
              :items="sessions"
              item-text="name"
              return-object
              label="Sélectionnez une session"
              v-model="selectedSession"
              @change="OnSessionSelected"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
            v-if="selectedSession != null"
            v-show="profile.role != 'User'"
          >
            <v-select
              :items="teams"
              item-text="name"
              return-object
              label="Sélectionnez une équipe"
              v-model="selectedTeam"
              @change="OnSelection"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.name + ' - ' + item.publicName"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="4" v-if="selectedSession != null">
            <v-select
              :items="periods"
              item-text="name"
              return-object
              label="Sélectionnez une période"
              v-model="selectedPeriod"
              @change="OnSelection"
            ></v-select>
          </v-col>
        </v-row>
        <span
          v-if="
            selectedPeriod &&
            selectedTeam &&
            currentResearches &&
            (isVisible || profile.role != 'User')
          "
        >
          <v-alert
            class="mt-3"
            border="left"
            color="error"
            icon="mdi-finance"
            outlined
            text
            v-if="
              selectedPeriod && selectedTeam && currentResearches && !isVisible
            "
          >
            Cette étude n'est pas visible pour les utilisateurs (publier les
            résultats pour les rendre visibles)
          </v-alert>
          <v-tabs v-model="currentTab" color="primary" centered>
            <v-tab>
              <span>Etudes de marché</span>
            </v-tab>
            <v-tab>
              <span>Etudes RH</span>
            </v-tab>
            <v-tab>
              <span>Etudes d'attractivité</span>
            </v-tab>
            <v-tab>
              <span>Etudes RSE</span>
            </v-tab>
            <v-tab>
              <span>Etudes de coûts</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab" id="researchWraper">
            <v-tab-item>
              <span id="marketResearch">
                <span
                  v-if="
                    currentResearches.purchased.marketResearch ||
                    profile.role != 'User'
                  "
                >
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="red"
                    icon="mdi-alert-outline"
                    outlined
                    text
                    v-if="
                      !currentResearches.purchased.marketResearch &&
                      profile.role != 'User'
                    "
                  >
                    Cette utilisateur n'a pas acheté l'étude de marché
                  </v-alert>
                  <MarketResearch
                    :profile="profile"
                    :user="user"
                    :researches="currentResearches"
                    :session="selectedSession"
                    :currentUniverseId="currentUniverseId"
                  />
                </span>
                <span v-else>
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="warning"
                    icon="mdi-finance"
                    outlined
                    text
                  >
                    Vous ne disposez pas de cette étude
                  </v-alert>
                </span>
              </span>
            </v-tab-item>
            <v-tab-item>
              <span>
                <span
                  id="hrResearch"
                  v-if="
                    currentResearches.purchased.hrResearch ||
                    profile.role != 'User'
                  "
                >
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="red"
                    icon="mdi-alert-outline"
                    outlined
                    text
                    v-if="
                      !currentResearches.purchased.hrResearch &&
                      profile.role != 'User'
                    "
                  >
                    Cette utilisateur n'a pas acheté l'étude de marché
                  </v-alert>
                  <HrResearch
                    :profile="profile"
                    :user="user"
                    :researches="currentResearches"
                    :session="selectedSession"
                    :currentUniverseId="currentUniverseId"
                  />
                </span>
                <span v-else>
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="warning"
                    icon="mdi-finance"
                    outlined
                    text
                  >
                    Vous ne disposez pas de cette étude
                  </v-alert>
                </span>
              </span>
            </v-tab-item>
            <v-tab-item>
              <span>
                <span
                  id="attractivityResearch"
                  v-if="
                    currentResearches.purchased.attractivityResearch ||
                    profile.role != 'User'
                  "
                >
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="red"
                    icon="mdi-alert-outline"
                    outlined
                    text
                    v-if="
                      !currentResearches.purchased.attractivityResearch &&
                      profile.role != 'User'
                    "
                  >
                    Cette utilisateur n'a pas acheté l'étude de marché
                  </v-alert>
                  <AttractivityResearch
                    :profile="profile"
                    :user="user"
                    :researches="currentResearches"
                    :session="selectedSession"
                  />
                </span>
                <span v-else>
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="warning"
                    icon="mdi-finance"
                    outlined
                    text
                  >
                    Vous ne disposez pas de cette étude
                  </v-alert>
                </span>
              </span>
            </v-tab-item>
            <v-tab-item>
              <span>
                <span
                  id="rseResearch"
                  v-if="
                    currentResearches.purchased.rseResearch ||
                    profile.role != 'User'
                  "
                >
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="red"
                    icon="mdi-alert-outline"
                    outlined
                    text
                    v-if="
                      !currentResearches.purchased.rseResearch &&
                      profile.role != 'User'
                    "
                  >
                    Cette utilisateur n'a pas acheté l'étude de marché
                  </v-alert>
                  <RseResearch
                    :profile="profile"
                    :user="user"
                    :researches="currentResearches"
                    :session="selectedSession"
                  />
                </span>
                <span v-else>
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="warning"
                    icon="mdi-finance"
                    outlined
                    text
                  >
                    Vous ne disposez pas de cette étude
                  </v-alert>
                </span>
              </span>
            </v-tab-item>
            <v-tab-item>
              <span>
                <span
                  id="costsResearch"
                  v-if="
                    currentResearches.purchased.costsResearch ||
                    profile.role != 'User'
                  "
                >
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="red"
                    icon="mdi-alert-outline"
                    outlined
                    text
                    v-if="
                      !currentResearches.purchased.costsResearch &&
                      profile.role != 'User'
                    "
                  >
                    Cette utilisateur n'a pas acheté l'étude de marché
                  </v-alert>
                  <CostsResearch
                    :profile="profile"
                    :user="user"
                    :researches="currentResearches"
                    :session="selectedSession"
                  />
                </span>
                <span v-else>
                  <v-alert
                    class="mt-3"
                    border="left"
                    color="warning"
                    icon="mdi-finance"
                    outlined
                    text
                  >
                    Vous ne disposez pas de cette étude
                  </v-alert>
                </span>
              </span>
            </v-tab-item>
          </v-tabs-items>
        </span>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
import Session from "@/classes/Session";
import Univers from "@/classes/Univers";
import Period from "@/classes/Period";
import Team from "@/classes/Team";
import Research from "@/classes/Research";

import dataFormater from "@/assets/functions/dataFormater.js";

import MarketResearch from "@/components/researches/MarketResearch.vue";
import RseResearch from "@/components/researches/RseResearch.vue";
import HrResearch from "@/components/researches/HrResearch.vue";
import AttractivityResearch from "@/components/researches/AttractivityResearch.vue";
import CostsResearch from "@/components/researches/CostsResearch.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";
import Result from "../classes/Result";

export default {
  name: "Researches",
  props: ["user", "profile", "userSession"],
  components: {
    MarketResearch,
    HrResearch,
    AttractivityResearch,
    RseResearch,
    CostsResearch,
  },
  setup() {
    return {
      dataFormater,
    };
  },
  data() {
    return {
      unsub: [],
      sessions: [],
      teams: [],
      periods: [],

      selectedSession: null,
      selectedTeam: null,
      selectedPeriod: null,

      currentTab: null,

      isVisible: false,
      currentResearches: null,
      currentUniverseId: null,
    };
  },

  watch: {
    selectedTeam: "updateCurrentUniverse",
  },
  async created() {
    if (
      this.profile &&
      this.profile.role == "User" &&
      this.profile.team != null
    ) {
      this.selectedTeam = await Team.getById(this.profile.team);
      let currentUnivers = await Univers.getById(this.selectedTeam.univers);
      this.selectedSession = await Session.getById(currentUnivers.session);

      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    } else {
      this.unsub.push(
        Session.listenOnlySession((sessions) => {
          sessions.sort((a, b) => b.date - a.date);
          this.sessions = sessions;
        })
      );
    }
  },
  methods: {
    async updateCurrentUniverse() {
      if (!this.selectedTeam) {
        this.currentUniverseId = null;
        return;
      }
      let currentUnivers = await Univers.getById(this.selectedTeam.univers);
      this.currentUniverseId = currentUnivers.id;

      // console.log("SELECTED UNIVERSE ID", this.currentUniverseId);
    },
    async OnSessionSelected() {
      let sessionUniverses = await Univers.getBySession(
        this.selectedSession.id
      );
      let tmp_teams = [];
      this.teams = [];

      this.selectedPeriod = null;
      this.selectedTeam = null;

      this.currentResearches = null;

      sessionUniverses.forEach((univers) => {
        this.unsub.push(
          Team.listenByUnivers(univers.id, (teams) => {
            teams.forEach((team) => {
              tmp_teams.push(team);
            });
            tmp_teams.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            this.teams = tmp_teams;
          })
        );
      });
      this.unsub.push(
        Period.listenBySession(this.selectedSession.id, (periods) => {
          this.periods = periods;
          this.periods = periods.filter((period) => {
            return period.start <= new Date().getTime();
          });
          this.periods.sort((a, b) => {
            return a.end - b.end;
          });
        })
      );
    },
    async OnSelection() {
      if (this.selectedTeam != null && this.selectedPeriod != null) {
        await this.loadResearches();
      }
    },
    async loadResearches() {
      let results = await Result.getByPeriodAndTeam(
        this.selectedPeriod.id,
        this.selectedTeam.id
      );
      if (results.length > 0) {
        let currentResult = results[0];
        this.isVisible = currentResult.isVisible;
      } else {
        this.isVisible = false;
      }

      this.unsub.push(
        Research.listenByPeriodAndTeam(
          this.selectedPeriod.id,
          this.selectedTeam.id,
          (results) => {
            this.currentResearches = results[0];
          }
        )
      );
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
